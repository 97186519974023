/**
 *  404 page
 *
 *  This page will not be shown on dev mode
 *  However you can explicitly route to it
 */

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = (): JSX.Element => (
  <Layout>
    <SEO title="404: Seite nicht gefunden" />
    <h1>Seite nicht gefunden</h1>
    <p>Tut uns leid, diese Seite wurde nicht gefunden</p>
  </Layout>
)

export default NotFoundPage
